import React from 'react';
import './style.scss';

function NotFound() {
  return (
    <main>
      <p>
        Ugh great, you broke it. Click on any options up there to get back on
        track.
      </p>
    </main>
  );
}

export default NotFound;
